import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom'; 
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faUser, faBriefcase, faHammer } from '@fortawesome/free-solid-svg-icons';

function Header() {
    const [isOpen, setIsOpen] = useState(false);

    const menuItems = [
        { to: "/", icon: faHouse, label: "Accueil" },
        { to: "/propos", icon: faUser, label: "À propos" },
        { to: "/experience", icon: faBriefcase, label: "Expérience" },
        { to: "/projets", icon: faHammer, label: "Projets" },
    ];

    return (
        <header 
            className={isOpen ? 'open' : ''} 
            onMouseEnter={() => setIsOpen(true)} 
            onMouseLeave={() => setIsOpen(false)}
        >
            <ul>
                {menuItems.map((item, index) => (
                    <li key={index}>
                        <Link to={item.to}>
                            <motion.div
                                className="menu-item"
                                initial={{ width: "60px" }}
                                animate={{ width: isOpen ? "150px" : "60px" }}
                                transition={{ duration: 0.5, ease: "easeInOut" }}
                                whileHover={{ scale: 1.15 }}
                            >
                                <motion.div
                                    className="icon-container"
                                    whileHover={{ scale: 1.2 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <FontAwesomeIcon icon={item.icon} className="icon" />
                                </motion.div>
                                <span className="menu-label">{item.label}</span>
                            </motion.div>
                        </Link>
                    </li>
                ))}
            </ul>
        </header>
    );
}

export default Header;
