import React from 'react';
import './About.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faPhp, faCss3, faHtml5, faWordpress, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { faHouse, faPhone, faEnvelope, faPencil, faDatabase, faBatteryFull, faComments, faHandshakeSimple, faLightbulb, faCode, faGamepad, faBriefcase, faDumbbell } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import Photo from '../Images/Photo2.png';
import French from '../Images/French.svg';
import English from '../Images/English.svg';
import German from '../Images/German.svg';

function About() {
    const bounceVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.2, 
                type: "spring",
                stiffness: 100,
                damping: 10,
            },
        }),
    };

    return (
        <div className='background-about'>
            <div className='container-profil'>
                <div className='container-img'>
                    <img src={Photo} alt="" />
                </div>
                <div className='container-info'>
                    <motion.h1 
                        className='title' 
                        variants={bounceVariants} 
                        initial="hidden" 
                        animate="visible"
                        custom={0} 
                    >
                        Ma biographie
                    </motion.h1>

                    <motion.p 
                        className='text' 
                        variants={bounceVariants} 
                        initial="hidden" 
                        animate="visible"
                        custom={1} 
                    >
                        Je m'appelle Maxime Séchaud, né le 6 août 2004 (20 ans). Passionné par les nouvelles technologies et les tendances du web, je suis actuellement en première année de CFC Médiamatique à l'Orif de Pomy, où je développe mes compétences en programmation et en communication digitale. Mon parcours m’a permis d’explorer divers domaines, comme la logistique et la bureautique, ce qui m'a donné une expérience polyvalente. Aujourd'hui, je me spécialise dans le développement web. Curieux et motivé, j'aime découvrir de nouvelles technologies et relever des défis techniques pour concrétiser des projets créatifs et fonctionnels.
                    </motion.p>

                    <div className='container-box'>
                        {[
                            { title: 'Contact', custom: 2, content: (
                                <>
                                    <p><FontAwesomeIcon className='icon' icon={faHouse} />Chemin de l'Orio 13,<br />1032 Romanel sur Lausanne</p>
                                    <p><FontAwesomeIcon className='icon' icon={faPhone} />+41 78 810 71 22</p>
                                    <p><FontAwesomeIcon className='icon' icon={faEnvelope} />maximesechaud32@gmail.com</p>
                                </>
                            )},
                            { title: 'Compétences Techniques', custom: 3, content: (
                                <>
                                    <div className='skills-section'>
                                        <p><FontAwesomeIcon className='icon' icon={faHtml5} />HTML</p>
                                        <p><FontAwesomeIcon className='icon' icon={faCss3} />CSS</p>
                                    </div>
                                    <div className='skills-section'>
                                        <p><FontAwesomeIcon className='icon' icon={faReact} />ReactJS</p>
                                        <p><FontAwesomeIcon className='icon' icon={faPhp} />PHP</p>
                                    </div>
                                    <div className='skills-section'>
                                        <p><FontAwesomeIcon className='icon' icon={faDatabase} />MySQL</p>
                                        <p><FontAwesomeIcon className='icon' icon={faWordpress} />WordPress</p>
                                    </div>
                                    <div className='skills-section'>
                                        <p><FontAwesomeIcon className='icon' icon={faPencil} />Suite Adobe</p>
                                        <p><FontAwesomeIcon className='icon' icon={faMicrosoft} />Suite Office</p>
                                    </div>
                                </>
                            )},
                            { title: 'Points forts', custom: 4, content: (
                                <>
                                    <p><FontAwesomeIcon className='icon' icon={faBatteryFull} />Autonomie</p>
                                    <p><FontAwesomeIcon className='icon' icon={faComments} />Communication</p>
                                    <p><FontAwesomeIcon className='icon' icon={faHandshakeSimple} />Fiabilité</p>
                                    <p><FontAwesomeIcon className='icon' icon={faLightbulb} />Prise d’initiative</p>
                                </>
                            )},
                            { title: 'Mes loisirs', custom: 5, content: (
                                <>
                                    <p><FontAwesomeIcon className='icon' icon={faCode} />Programmation</p>
                                    <p><FontAwesomeIcon className='icon' icon={faGamepad} />Jeux vidéo</p>
                                    <p><FontAwesomeIcon className='icon' icon={faBriefcase} />Entreprenariat</p>
                                    <p><FontAwesomeIcon className='icon' icon={faDumbbell} />Fitness</p>
                                </>
                            )},
                            { title: 'Langues', custom: 6, content: (
                                <>
                                    <p><img src={French} alt="Français" />Langue maternelle</p>
                                    <p><img src={English} alt="Anglais" />A2</p>
                                    <p><img src={German} alt="Allemand" />Notions scolaires</p>
                                </>
                            )}
                        ].map((item, i) => (
                            <motion.div 
                                className='box' 
                                key={i}
                                variants={bounceVariants} 
                                initial="hidden" 
                                animate="visible"
                                custom={item.custom}
                            >
                                <h2 style={{ color: "#f99644" }}>{item.title}</h2>
                                {item.content}
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
