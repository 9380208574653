import React from 'react';
import './Experience.css';
import { motion } from 'framer-motion';

function Experience() {
    const moisOrdre = {
        "Janvier": 1, "Février": 2, "Mars": 3, "Avril": 4,
        "Mai": 5, "Juin": 6, "Juillet": 7, "Août": 8,
        "Septembre": 9, "Octobre": 10, "Novembre": 11, "Décembre": 12
    };

    const experiences = [
        { year: "2017", month: "Novembre", title: "Stage en cuisine, Hôtel de Ville à Crissier", description: "Première immersion dans le monde de la gastronomie au prestigieux restaurant de l'Hôtel de Ville de Crissier." },
        { year: "2018", month: "Janvier", title: "Stage en cuisine, GastroVaud à Pully", description: "Expérience enrichissante dans le domaine culinaire, avec un apprentissage approfondi des techniques de cuisine." },
        { year: "2021", month: "Décembre", title: "Programme pré-professionnel de 3 mois, MDJ'ump à Lausanne", description: "Initiation au développement web avec une formation pratique en HTML, CSS, JavaScript et PHP." },
        { year: "2022", month: "Juin", title: "Stage en dessin technique, Atau à Lausanne", description: "Découverte des fondamentaux du design numérique et de la création de maquettes techniques." },
        { year: "2022", month: "Avril", title: "Stage linguistique, Angleterre", description: "Immersion linguistique en Angleterre pour renforcer mes compétences en anglais dans un contexte professionnel." },
        { year: "2022", month: "Août", title: "CFC 1re année en spécialisation restauration, CHUV à Lausanne", description: "Début de formation en restauration, avec une initiation aux services et aux pratiques professionnelles en milieu hospitalier." },
        { year: "2023", month: "Avril", title: "Stage en logistique, Caritas à Lausanne", description: "Expérience en gestion logistique, avec des missions variées dans le tri, le stockage et la distribution de matériel." },
        { year: "2023", month: "Août", title: "Réhabilitation au travail, Orif à Pomy", description: "Expérience enrichissante en bureautique et comptabilité, avec des missions variées en gestion administrative et utilisation des outils bureautiques." },
        { year: "2024", month: "Avril", title: "Stage en Conseiller d'assurance, Fragniere Assureur-conseil Sàrl", description: "Introduction au fonctionnement du secteur de l'assurance. Observation du rôle de conseiller en assurance, avec un aperçu des processus de gestion des contrats et des interactions avec les clients pour identifier leurs besoins d’assurance." },
        { year: "2024", month: "Août", title: "Début d'apprentissage CFC Médiamatique, Orif à Pomy", description: "Lancement de mon apprentissage en médiamatique pour acquérir des compétences en technologies numériques et communication." }
    ];

    experiences.sort((a, b) => {
        if (b.year === a.year) {
            return moisOrdre[b.month] - moisOrdre[a.month];
        }
        return b.year - a.year;
    });

    const boxVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <div className='background-experience'>
            <div className='container-text'>
                <h1 className='title'>Mon expérience professionnelle</h1>
                <p className='text'>
                Depuis l'âge de 13 ans, j'ai exploré divers secteurs professionnels, enrichissant ainsi mon parcours et mes compétences. Après une immersion en cuisine dans des établissements prestigieux comme l'Hôtel de Ville de Crissier, j'ai acquis des expériences variées en développement web, design technique, logistique et assurance. Actuellement en apprentissage en médiamatique à l'Orif, je développe des compétences en technologies numériques et communication, des bases solides pour mon avenir professionnel.
                </p>
            </div>
            <div className='timeline'>
                {experiences.map((exp, index) => (
                    <motion.div 
                        key={index} 
                        className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}
                        initial="hidden"
                        whileInView="visible"
                        variants={boxVariants}
                        viewport={{ once: true, amount: 0.1 }} 
                        transition={{
                            delay: index * 0.2,
                            duration: 0.5,
                            ease: "easeOut",
                        }}
                    >
                        <div className='timeline-content'>
                            <h3>{exp.month} {exp.year} - {exp.title}</h3>
                            <p>{exp.description}</p>
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
}

export default Experience;
