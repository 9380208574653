import React from 'react';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Profil from '../Images/Photo.png';
import Typewriter from 'typewriter-effect';
import { motion } from 'framer-motion';

function Home() {
    return (
        <div className='background-home'>
            <div className='container-profil'>
                <motion.div
                    className='container-img'
                    animate={{ scale: [0.95, 1] }}
                    transition={{
                        duration: 2,
                        repeat: Infinity,
                        repeatType: "reverse"
                    }}
                >
                    <img src={Profil} alt="Profil" />
                </motion.div>
                <div className='container-text'>
                    <p>
                        Je m'appelle <span style={{ color: "#f99644" }}>Maxime Séchaud</span>,
                    </p>
                    <div className='text'>
                        <Typewriter
                            options={{
                                strings: [
                                    "j'ai 20 ans et je suis actuellement en 1ère année de formation CFC Médiamaticien à l'Orif de Pomy, où je me forme aux bases du numérique et aux outils technologiques essentiels pour le web."
                                ],
                                autoStart: true,
                                loop: false,
                                delay: 30,
                                pauseFor: 3000000
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className='container-contact'>
                <div className='contact'>
                    <FontAwesomeIcon className='icon' icon={faEnvelope} />
                    <p>maximesechaud32@gmail.com</p>
                </div>
                <div className='contact'>
                    <FontAwesomeIcon className='icon' icon={faPhone} />
                    <p>+41 78 810 71 22</p>
                </div>
            </div>

            <div className='container-made'>
                <p>Ce site web a été conçu avec la technologie ReactJS.</p>
            </div>
        </div>
    );
}

export default Home;
