import React from 'react';
import './Portfolio.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendarDays, faCode, faEye } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion'; 

import Webprogress from '../Images/Webprogress.svg';
import Casanova from '../Images/Casanova.webp';
import GarageFuniculaire from '../Images/GarageFuniculaire.svg';
import EEF from '../Images/EEF.png';
import Alexotique from '../Images/Alexotique.webp';
import AficorSA from '../Images/AficorSA.png';

function Portfolio() {
    
    const projects = [
        { id: 1, image: EEF, title: 'Étagère en folie', description: "Le site de l'Étagère en folie est ma toute première application web, conçue sans l'utilisation de framework, en HTML et PHP pur. Mandaté par un maître socio-professionnel de l'Orif de Pomy, ce projet m'a permis de développer une plateforme de gestion de bibliothèque en ligne, offrant aux utilisateurs un suivi efficace de leurs ressources et facilitant l'organisation des ouvrages. Ce projet a été un tournant dans ma carrière, m'ouvrant la voie vers mon futur métier en tant que développeur full stack, une voie que je souhaite désormais poursuivre avec passion.", hours: '160h', date: '10.02.2024', tech: 'HTML / PHP', link: 'https://etagere-en-folie.ch/' },
        { id: 2, image: Casanova, title: 'Chantier Casanova', description: "Chantier Casanova est un site web vitrine conçu pour une société de chantier naval. Ce projet, mon tout premier mandat externe, m’a été confié par un ami qui lance actuellement sa propre entreprise IT. Il a accepté de me faire confiance pour réaliser ce site afin de me permettre de m'exercer, et le résultat a été un succès : tant mon ami que le client ont été ravis du travail final.", hours: '40h', date: '10.07.2024', tech: 'ReactJS', link: 'https://chantiercasanova.ch/' },
        { id: 3, image: GarageFuniculaire, title: 'Garage du funiculaire', description: "Garage du funiculaire est une application web que j'ai développée bénévolement pour un client externe dans le cadre de la société de mon ami, afin de renforcer mes compétences. Ce projet comprend un système d'administration permettant de modifier la pop-up d'accueil du site, offrant ainsi une gestion simplifiée et dynamique pour le client.", hours: '60h', date: '15.08.2024', tech: 'ReactJS / PHP', link: 'https://garagedufuniculaire.ch/' },
        { id: 4, image: Alexotique, title: 'Alexotique', description: 'Alexotique est un site vitrine pour un restaurant thaï situé à Morrens. Le site inclut une fonctionnalité permettant au patron de modifier en temps réel le menu du jour, offrant ainsi une flexibilité optimale pour mettre à jour les plats proposés aux clients.', hours: '50h', date: '20.08.2024', tech: 'ReactJS / PHP', link: 'https://alexotique.ch/' },
        { id: 5, image: Webprogress, title: 'Webprogress', description: "Webprogress est l'application la plus complète et complexe que j'ai réalisée à ce jour. Conçue pour la société de mon ami, elle permet de créer un dossier pour chaque nouveau mandat obtenu, avec une gestion avancée des utilisateurs et la possibilité de créer des projets tels que MyWebsite et DigitalCampaigns. Les clients ont accès à leur dossier personnalisé, peuvent uploader des fichiers pour enrichir leurs projets (photos, documents, etc.), et disposent d'un chat réactif pour communiquer directement avec les employés responsables de leur projet digital. Lors de la création du projet, un code unique est envoyé par mail au client pour un accès sécurisé.", hours: '140h', date: '20.08.2024', tech: 'ReactJS / PHP', link: 'https://webprogress.logixmedia.ch/' },
        { id: 6, image: AficorSA, title: 'Aficor SA', description: "Aficor SA est un site web vitrine que j'ai développé en dehors de ma formation, sur mon temps libre, pour un ami souhaitant actualiser son site. Il met en avant les services d'Aficor SA, spécialisés dans les vérins hydrauliques, l'oxycoupage, les réparations, les fendeuses et treuils. Ce site permet au propriétaire d'ajouter des articles à vendre avec leurs caractéristiques et inclut un système de mise à jour des actualités.", hours: '80h', date: '01.09.2024', tech: 'ReactJS / PHP', link: '#' }
    ];    

    const boxVariants = {
        hidden: { opacity: 0, y: -50 },
        visible: { opacity: 1, y: 0 } 
    };

    return (
        <div className="background-portfolio">
            <div className="container-portfolio">
                <h1 className='title'>Mes réalisations concrètes</h1>
                <p className='text'>Voici la liste de mes réalisations depuis le début de ma formation en tant que médiamaticien. Ces projets ont été réalisés soit dans le cadre de ma formation pour mon employeur, L'Orif, soit pendant mon temps libre pour enrichir mes compétences en programmation web. Ils sont principalement développés en langages web (HTML, CSS, JavaScript) et non avec des CMS.</p>
                {projects.map((project, index) => (
                    <motion.div 
                        className="box"
                        key={project.id}
                        initial="hidden"
                        whileInView="visible" 
                        viewport={{ once: true, amount: 0.1 }}
                        variants={boxVariants}
                        transition={{
                            delay: index * 0.2,
                            duration: 0.5,
                            ease: "easeInOut",
                        }}
                    >
                        <div className='container-number'>
                            <span>Projet #{project.id}</span>
                        </div>
                        <div className='container-box'>
                            <div className="container-img">
                                <img src={project.image} alt={project.title} />
                            </div>
                            <div className="container-information">
                                <div className="container-text">
                                    <h2>{project.title}</h2>
                                    <p>{project.description}</p>
                                </div>
                                <div className="container-info">
                                    <div className="icon">
                                        <FontAwesomeIcon className="icon-font" icon={faClock} />
                                        <span>{project.hours}</span>
                                    </div>
                                    <div className="icon">
                                        <FontAwesomeIcon className="icon-font" icon={faCalendarDays} />
                                        <span>{project.date}</span>
                                    </div>
                                    <div className="icon">
                                        <FontAwesomeIcon className="icon-font" icon={faCode} />
                                        <span>{project.tech}</span>
                                    </div>
                                    <div className="icon">
                                        <FontAwesomeIcon className="icon-font" icon={faEye} />
                                        <a href={project.link}>Voir le projet</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
}

export default Portfolio;
