import React, { useState, useEffect } from 'react';
import { useLocation, Route, Routes, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import Header from './CV/Header/Header';
import Home from './CV/Home/Home';
import About from './CV/About/About';
import Portfolio from './CV/Portfolio/Portfolio';
import Experience from './CV/Experience/Experience';
import Loader from './CV/Loader/Loader'; 
import './App.css'; 

function App() {
    const [loading, setLoading] = useState(false);
    const [showResizeMessage, setShowResizeMessage] = useState(false);
    const location = useLocation(); 
    const navigate = useNavigate(); 

    useEffect(() => {
        setLoading(true); 
        const timer = setTimeout(() => {
            setLoading(false); 
        }, 2000); 

        return () => clearTimeout(timer); 
    }, [location]); 

    useEffect(() => {
        const handleResize = () => {
            const { innerWidth, innerHeight } = window;
            if (innerWidth < 1300 || innerHeight < 800) {
                setShowResizeMessage(true);
            } else {
                setShowResizeMessage(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            {showResizeMessage ? (
                <div className="resize-message">
                    <FontAwesomeIcon className='icon' icon={faDesktop} />
                    <p>Ce contenu est optimisé pour les écrans larges.</p>
                </div>
            ) : (
                <>
                    <motion.h1
                        initial={{ scale: 1, rotate: 0 }}
                        whileHover={{ scale: 1.1, rotate: [0, 10, -10, 5, 0] }}
                        transition={{
                            duration: 0.8,
                            ease: "easeInOut"
                        }}
                        className="animated-title"
                        onClick={() => navigate("/")} 
                        style={{ cursor: 'pointer' }} 
                    >
                        <span>Maxime</span> Séchaud
                    </motion.h1>

                    <Header />
                    {loading ? ( 
                        <Loader />  
                    ) : ( 
                        <Routes>  
                            <Route path="/" element={<Home />} />
                            <Route path="/propos" element={<About />} />
                            <Route path="/experience" element={<Experience />} />
                            <Route path="/projets" element={<Portfolio />} />
                        </Routes>
                    )}
                </>
            )}
        </div>
    );
}

export default App;
